import {NgModule} from '@angular/core';
import {LoginComponent} from './login/login.component';
import {ForgetpasswordComponent} from "./forgetpassword/forgetpassword.component";
import {ResetpasswordComponent} from "./resetpassword/resetpassword.component";
import {
    RouterModule,
    Routes,
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import {GuideComponent} from "./guide/guide.component";

const mainRoute: Routes = [
    {path:'',
    redirectTo:'login',
        pathMatch:'full'},
    {path: 'login', component: LoginComponent,data: {title: 'Login'}},
    {path: 'password/email', component: ForgetpasswordComponent,data: {title: 'Password Reset'}},
    {path: 'password/reset/:reset_token', component: ResetpasswordComponent,data: {title: 'Password Reset'}},
    {path: 'dashboard', loadChildren:'src/app/layout/layout.module#DashboardModule'},
    {path: 'guide', component:GuideComponent,data: {title: 'Guide'}},
];

@NgModule({
    imports: [
        RouterModule.forRoot(mainRoute,{useHash: true})
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {
}
