import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-employeehome',
  templateUrl: './employeehome.component.html',
  styleUrls: ['./employeehome.component.css']
})
export class EmployeehomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
      window.scrollTo(0, 0);
  }

}
