// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,

    // Local
     //BASE_URL: 'http://192.168.1.120:8000/api/',
    //BASE_URL: 'http://localhost:8000/api/',
     //SITE_URL: 'http://localhost:4200/#/',
    // BASE_URL: 'http://35.154.109.35/brt-portal/public/index.php/api/',
    // SITE_URL: 'http://35.154.109.35/brt-angular/#/',

    // Demo
      //BASE_URL:'https://api.hrms.bluerose.solutions/api/',
      // BASE_URL: 'https://employeeapi.bluerose.solutions/',
      // SITE_URL: 'https://employee.bluerose.solutions/#/',
      //SITE_URL: 'https://hrms.bluerose.solutions/#/',
      // SITE_URL: 'http://hrms/',
     //BASE_URL: 'http://api.hrms/api/',

    // Live
    BASE_URL: 'https://employeeapi.bluerose-tech.com/api/',
    SITE_URL: 'https://employee.bluerose-tech.com/#/',

    HR_ROLE: '2',
    EMPLOYEE_ROLE: '1',
    CANDIDATE_ROLE: '3',
    RECRUITMENT_ROLE:'5',
    ADMIN_ROLE: '6',
    GHR: '7',
    MANAGER_ROLE: '4',
    FINANCE:'8',
  TEAM_MEMBER_ROLE: '9',
    FILE_ICON_URL: 'assets/img/file_icon.png',
    IMAGE_ICON_URL: 'assets/img/image_icon.png',
    PDF_ICON_URL: 'assets/img/document_icon.png',
    WORD_ICON_URL: 'assets/img/word_icon.png',
    EXCEL_ICON_URL: 'assets/img/excel_icon.png',
    APP_NAME: 'BRT Portal'

};


