import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpModule,BrowserXhr} from '@angular/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FooterComponent} from './footer/footer.component';
import {SidemenuComponent} from './sidemenu/sidemenu.component';
import {HeaderComponent} from './header/header.component';
import {DashboardRoutingModule} from './layout.routing.module';
import {LayoutComponent} from './layout.component';
import {HomeComponent} from '../home/home.component';
import {EmployeehomeComponent} from '../employeehome/employeehome.component';
import {FileUploadModule} from 'ng2-file-upload';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { FullCalendarModule } from 'ng-fullcalendar';
import {NgProgressHttpClientModule} from "@ngx-progressbar/http-client";
import {NgProgressModule} from "@ngx-progressbar/core";
import {NotfoundComponent} from "../notfound/notfound.component";
import {PreviewComponent} from "../hr/preview/preview.component";
import {FaqComponent} from "../faq/faq.component";
import {AccordionModule} from 'primeng/accordion';
import {MatExpansionModule} from '@angular/material/expansion';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        HttpModule,
        DashboardRoutingModule,
        FileUploadModule,
        FormsModule,
        FullCalendarModule,
        NgxSmartModalModule.forRoot(),
        AccordionModule,
        MatExpansionModule
    ],
    declarations: [
        SidemenuComponent,
        HeaderComponent,
        LayoutComponent,
        HomeComponent,
        EmployeehomeComponent,
        PreviewComponent,
        NotfoundComponent,
        FaqComponent
    ],
    exports: [
        SidemenuComponent,
        HeaderComponent,
        LayoutComponent,
        HomeComponent,
        PreviewComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    providers: []
})
export class DashboardModule {
}
