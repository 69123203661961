import {NgModule, Injectable} from '@angular/core';
import {
    RouterModule,
    Routes,
    Router,
    ActivatedRoute,
    CanActivate,
    CanActivateChild,
    CanDeactivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import {LayoutComponent} from './layout.component';
import {ProfileComponent} from '../hr/profile/profile.component';
import { environment as env} from '../../environments/environment';
import {NotfoundComponent} from "../notfound/notfound.component";
import {PreviewComponent} from "../hr/preview/preview.component";
import {FaqComponent} from "../faq/faq.component";

@Injectable()
export class OnlyHRUsersGuard implements CanActivate {
    constructor(public router: Router) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {


        if (localStorage.getItem('login_success')) {
            if (localStorage.getItem('role') === env.HR_ROLE || localStorage.getItem('role') === env.GHR) {
                return true;
            } else {
                this.router.navigate([''], {queryParams: {returnUrl: state.url}});
                return false;
            }
        } else {

            this.router.navigate([''], {queryParams: {returnUrl: state.url}});
            return false;
        }
    }
}
@Injectable()
export class OnlyCandidateUsersGuard implements CanActivate {
    constructor(public router: Router) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (localStorage.getItem('login_success')) {
            if (localStorage.getItem('role') === env.CANDIDATE_ROLE) {
                return true;
            } else {
                this.router.navigate([''], {queryParams: {returnUrl: state.url}});
                return false;
            }
        } else {
            this.router.navigate([''], {queryParams: {returnUrl: state.url}});
            return false;
        }
    }
}
@Injectable()
export class OnlyAdminGuard implements CanActivate {
    constructor(public router: Router) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (localStorage.getItem('login_success')) {
            if (localStorage.getItem('role') === env.ADMIN_ROLE) {
                return true;
            } else {
                this.router.navigate([''], {queryParams: {returnUrl: state.url}});
                return false;
            }
        } else {
            this.router.navigate([''], {queryParams: {returnUrl: state.url}});
            return false;
        }
    }
}


@Injectable()
export class OnlyEmployeeUsersGuard implements CanActivate {
    constructor(public router: Router) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (localStorage.getItem('login_success')) {
          const role = localStorage.getItem('role');
          const roles = [env.EMPLOYEE_ROLE, env.MANAGER_ROLE, env.FINANCE, env.RECRUITMENT_ROLE, env.TEAM_MEMBER_ROLE];
            if (roles.includes(role)) {
                return true;
            } else {
                this.router.navigate([''], {queryParams: {returnUrl: state.url}});
                return false;
            }
        } else {
            this.router.navigate([''], {queryParams: {returnUrl: state.url}});
            return false;
        }
    }
}

const UserRoutes: Routes = [
  {
    path: 'hr',
    component: LayoutComponent,
    canActivate: [OnlyHRUsersGuard],
    children: [
      {path: '', loadChildren:'src/app/hr/hr.module#HrModule'},
      {path: 'common', loadChildren:'src/app/common/common.module#OutterModule'},
      {path: 'offer', loadChildren:'src/app/offer/offer.module#OfferModule'},
    ]
  }, {
        path: 'candidate',
        component: LayoutComponent,
        canActivate: [OnlyCandidateUsersGuard],
        children: [
          {path: '', loadChildren:'src/app/employee/employee.module#EmployeeModule'},
        ]
  }, {
        path: 'employee',
        component: LayoutComponent,
        canActivate: [OnlyEmployeeUsersGuard],
        children: [
          {path: '', loadChildren:'src/app/employee/employee.module#EmployeeModule'},
          {path: 'common', loadChildren:'src/app/common/common.module#OutterModule'},
          {path: 'offer', loadChildren:'src/app/offer/offer.module#OfferModule'}
        ]
  }, {
        path: 'admin',
        component: LayoutComponent,
        canActivate: [OnlyAdminGuard],
        children: [
          {path: '', loadChildren:'src/app/admin/admin.module#AdminModule'},
        ]
  },
  {path: 'notfound', component: NotfoundComponent},
  {path: 'faq', component: FaqComponent},
  {path: 'documents/preview', component:PreviewComponent},
  {
    path: 'dashboard',
    redirectTo: 'notfound',
    pathMatch: 'full'
  }
];

@NgModule({
    imports: [
      RouterModule.forChild(UserRoutes)
    ],
    exports: [
      RouterModule,
    ],
    providers: [
      OnlyHRUsersGuard,
      OnlyCandidateUsersGuard,
      OnlyEmployeeUsersGuard,OnlyAdminGuard
    ]
})
export class DashboardRoutingModule {
}
