import {Component, OnInit} from '@angular/core';
import {environment as env} from '../../../environments/environment';


@Component({
    selector: 'app-sidemenu',
    templateUrl: 'sidemenu.component.html',
    styleUrls: ['sidemenu.component.css']
})
export class SidemenuComponent implements OnInit {
    role: any;
    roleValues:any = env;
    login_expiry_status:any;
    active_status:any;
    constructor() {
    }

    ngOnInit() {
        this.role= localStorage.getItem('role');
        this.login_expiry_status=localStorage.getItem('login_expiry_status');
        this.active_status=localStorage.getItem('active_status');
    }

    openFaq(){
      window.open(this.roleValues.SITE_URL + 'faq');
    }

    openProcess(){
      window.open('/assets/img/Process.pdf','_blank');
    }


  get isOuterPolicy() {
    const isEmployeeOrAdmin = [this.roleValues.EMPLOYEE_ROLE, this.roleValues.ADMIN_ROLE].includes(this.role);
    return isEmployeeOrAdmin && (this.login_expiry_status=='1' && this.active_status==1)
  }

  get isCandidateRole() {
    return this.roleValues.CANDIDATE_ROLE === this.role;
  }

  get isReconsileAllowed() {
    const { HR_ROLE, ADMIN_ROLE, FINANCE } = this.roleValues;
    return [HR_ROLE, ADMIN_ROLE, FINANCE].includes(this.role);
  }

  get isTeamMemberRole() {
    return this.roleValues.TEAM_MEMBER_ROLE === this.role;
  }
}
