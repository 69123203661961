import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {environment as env} from '../../../environments/environment';
import { Compiler } from '@angular/core';
import {CommonService} from "../../services/common/common.service";
import {ToastrService} from "ngx-toastr";




@Component({
    selector: 'app-header',
    templateUrl: 'header.component.html',
    styleUrls: ['header.component.css']
})
export class HeaderComponent implements OnInit {

    loggedUserName: any = '';
    role:any;
    multiRoles:any=[];

    constructor(private router: Router,private _compiler: Compiler,public  commonService:CommonService,public toastr:ToastrService) {

    }


    ngOnInit() {
        this.loggedUserName = localStorage.getItem('user_name');
        this.role =localStorage.getItem('role');
        const roles:any = JSON.parse(localStorage.getItem('multi_role')) || [];
        this.multiRoles = roles.map(role=>{
          const obj = {
            role_id: role,
            name: ''
          };
          switch(String(role)) {
            case env.HR_ROLE:
              obj.name = 'HR';
              break;
            case env.EMPLOYEE_ROLE:
              obj.name = 'Employee';
              break;
            case env.RECRUITMENT_ROLE:
              obj.name = 'Recruiter';
              break;
            case env.MANAGER_ROLE:
              obj.name = 'Manager';
              break;
            case env.FINANCE:
              obj.name = 'Finance';
              break;
            case env.GHR:
              obj.name = 'GHR';
              break;
            case env.ADMIN_ROLE:
              obj.name = 'Admin';
              break;
            case env.TEAM_MEMBER_ROLE:
              obj.name = 'Team Member';
          }
          return obj;
        }).filter(role=> !!role.name);
    }

    logout() {


        this.commonService.callLogoutApi().subscribe((response: any)=>{

            if(response.status){
                this.toastr.success(response.message,"Success!!!");
                localStorage.clear();
                localStorage.removeItem('token');
                this.router.navigate(['']);

            }

        })

    }

    ngOnDestroy(){

        localStorage.removeItem('token');

    }

    changeRole(event){

        this.router.navigate(['']);

        localStorage.setItem("role",event);

    }

    sidemenu() {
        const x = document.getElementById('container');

        if (x.className === 'fixed-header') {
            x.classList.add('sidebar-closed');
        }
        else if (x.className === 'fixed-header sidebar-closed') {
            x.classList.remove('sidebar-closed');
        }
    }

    mobilesideMenu(){

        const x = document.getElementById('mobileheader');

        if (x.className === 'Site-content') {

            x.classList.add('nav-open');
        }
        else if (x.className === 'Site-content nav-open') {
            x.classList.remove('nav-open');
        }
    }

    openGuide() {

        window.open(env.SITE_URL + 'guide');
    }

    clearCache(){

        localStorage.clear();
        localStorage.removeItem('token');
        this.router.navigate(['']);
        window.location.reload();
    }


}
