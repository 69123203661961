import { Component, OnInit } from '@angular/core';
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.css']
})
export class GuideComponent implements OnInit {

  role:any;

  constructor() {

    this.role= localStorage.getItem('role');


  }

  ngOnInit() {
    console.log(this.role);

  }



}
